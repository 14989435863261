$(document).ready(function() {
    $('.editable').each(function(index, element) {
        $(element).editable({
            mode: 'inline',
            type: 'text',
            send: 'always',
            params: {
                '_token': '{{ csrf_token() }}'
            },
            success: function(response, newValue) {
                if ($(element).hasClass('editable-reload')) {
                    location.reload();
                }
            }
        });
    });

    $('form .confirm').click(function(e) {
        e.preventDefault();

        bootbox.confirm("Naaa, sicher?", function(result) {
            if (result) e.target.closest('form').submit();
        }); 
    });

    $('[title]').tooltip();

    Dropzone.autoDiscover = false;
    $('.dropzone').dropzone({
        dictDefaultMessage: '<i class="fa fa-plus fa-2x"></i>',
        acceptedFiles: 'image/*',
        success: function() {
            location.reload();
        }
    });
}); 